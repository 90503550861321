<template>
	<div id="equipment">
		<CCard class="p-4">
			<form @submit.prevent="submit">
				<CCardHeader>
					<CRow style="color: #1352a1;">
				
						<CCol lg="6">
							<h5 v-if="!editMode">ADD FURNISHING</h5>
							<h5 v-else>UPDATE FURNISHING</h5>
						</CCol>
				     	<CCol lg="6">
				      		<div style="text-align: right">
				      			<CButton size="sm" @click="backToTable()" color="danger"><i class="fa fa-close"></i> Cancel</CButton> &nbsp;
					      		<CButton size="sm" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
				      		</div>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody class="card-form">
					
					<CCard class="p-4 mt-2">
					<CRow class="p-1 mb-2 bg-secondary text-info">
							<CCol lg="6">
							<strong style="font-size:12px"> FURNISHING DETAILS</strong>
						</CCol>
					</CRow>		
						<CRow class="ml-3 mr-3"> 
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Asset No. <span class="text-danger">*</span></label>
									<CInput 
					                 
					                  v-model="dataParams.asset_no"
					                  required="true"
					                />
								</div> 
							</CCol>  
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Type <span class="text-danger">* </span></label>
									<CInput 
					                 
					                  v-model="dataParams.type"
					                  required="true"
					                />
								</div> 
							</CCol>  
							<CCol lg="4">
								<div class="form-group"> 
									<label class="mb-1">Status <span class="text-danger">*</span> </label>
									<v-select 
										label="setting_name" 
										:options="[
											{
												id: 'operational',
												setting_name: 'Operational'
											},
											{
												id: 'breakdown',
												setting_name: 'Breakdown'
											},
											{
												id: 'for scrap',
												setting_name: 'For Scrap'
											},
											{
												id: 'standby',
												setting_name: 'Standby'
											},
											{
												id: 'for erection',
												setting_name: 'For Erection'
											},
											{
												id: 'for inspection',
												setting_name: 'For Inspection'
											},
											{
												id: 'reserve',
												setting_name: 'Reserve'
											},
											{
												id: 'dismantle',
												setting_name: 'Dismantle'
											},
											{
												id: 'for rehab',
												setting_name: 'For Rehab'
											},
											{
												id: 'purchase',
												setting_name: 'Purchase'
											},
											{
												id: 'pull-out',
												setting_name: 'Pull-out'
											},
											{
												id: 'transfer',
												setting_name: 'Transfer'
											},
											{
												id: 'advances to subcon',
												setting_name: 'Advances to Subcon'
											},
											{
												id: 'for repair',
												setting_name: 'For repair'
											}
										]"
										:reduce="item => item.id"
										v-model="dataParams.status"
										placeholder="-Select-" 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.status"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div>
							</CCol>   
						</CRow>
						<CRow class="ml-3 mr-3"> 
							
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Model  <span class="text-danger">* </span></label>
									<CInput 
					                 
					                  v-model="dataParams.model"
					                  required="true"
					                />
								</div> 
							</CCol>   
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">MFG / Brand</label>
									<CInput 
					                 
					                  v-model="dataParams.mfg_brand"
					                />
								</div> 
							</CCol>  
							
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Serial No. <span class="text-danger">* </span></label>
									<CInput 
									required="true"
					                  v-model="dataParams.serial_no"
					                />
								</div> 
							</CCol>   
						</CRow>
						<CRow class="ml-3 mr-3"> 
							<CCol lg="12">
								<div class="form-group">
									<label class="mb-1">Description  <span class="text-danger">* </span> </label>
									<CTextarea 
					                 
					                  v-model="dataParams.description"
					                  required="true"
					                />
								</div> 
							</CCol>  
						</CRow>
						<CRow class="ml-3 mr-3"> 
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Specification</label>
									<CInput 
					                 
					                  v-model="dataParams.specification" 
					                />
								</div> 
							</CCol>  
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Dimension</label>
									<CInput 
					                
					                  v-model="dataParams.dimentsion" 
					                />
								</div> 
							</CCol> 
							
							
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">P.O. No.</label>
									<CInput 
					                 
					                  v-model="dataParams.po_no"
					                />
								</div> 
							</CCol>  
						</CRow> 
						
						
						<CRow class="ml-3 mr-3"> 
							<CCol lg="6">
								<div class="form-group">
									<label class="mb-1">Acquisition Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.acquisition_date"
										
										
									>
									</Datepicker> 
								</div> 
							</CCol>  
							<CCol lg="6">
								<div class="form-group">
									<label class="mb-1">Acquisition Cost</label>
									<CInput 
					                  v-model="dataParams.acquisition_cost"
									  @keypress="isNumber($event)"
					                />
								</div> 
							</CCol>  
						</CRow>
						<CRow class="p-1 mb-2 bg-secondary text-info">
							<CCol lg="6">
							<strong style="font-size:12px"> OTHER DETAILS</strong>
							</CCol>
							</CRow>		
						<CRow class="ml-3 mr-3"> 
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Origin <span class="text-danger">* </span></label>
									<v-select 
										label="customer_name" 
										:options="originList.data"
										:reduce="item => item.id"
										v-model="dataParams.origin_id"
										placeholder="-Select-" 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.origin_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div> 
							</CCol>
							  
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Current Location  <span class="text-danger">* </span></label>
									<v-select 
										label="customer_name" 
										:options="customerList.data"
										@input="locationChanged($event)"
										:reduce="item => item.id"
										v-model="dataParams.current_location_id"
										placeholder="-Select-" 
									>
										<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.current_location_id"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
									</v-select>
								</div> 
							</CCol>  
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Depot</label>
									<CInput 
									v-if="dataParams.depot"
									  disabled
					                  v-model="dataParams.depot.setting_name" 
					                />
									<CInput 
										v-else
									  disabled
					                />
									
								</div> 
							</CCol>  
						</CRow>
						<CRow class="ml-3 mr-3"> 
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Rental Rate</label>
									<CInput 
									 @keypress="isNumber($event)"
					                  v-model="dataParams.rental_rate"
					                />
								</div> 
							</CCol>    
						
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">Transfer Date</label>
									<Datepicker 
										input-class="form-control bg-white" 
										v-model="dataParams.late_transfer_date"
										
									>
									</Datepicker> 
								</div> 
							</CCol>  
							<CCol lg="4">
								<div class="form-group">
									<label class="mb-1">MTS No.</label>
									<CInput 
					                
					                  v-model="dataParams.mts_no"
					                />
								</div> 
							</CCol>  
						</CRow>
						<CRow class="ml-3 mr-3">  
							<CCol lg="12">
								<div class="form-group">
									<label class="mb-1">Remarks</label>
									<CInput 
					                 
					                  v-model="dataParams.remarks"
					                />
								</div> 
							</CCol>  
						</CRow> 
						<CRow class="ml-3 mr-3">
							<CCol lg="3">
								<label for="default_photo">Photo</label><br>
								<input type="file" id="default_photo">
								<label for="default_photo" style="max-width:180px; overflow: hidden;"><br>
									<img style="width: 100%; cursor: pointer" id="display_default_photo" src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg" v-if="!dataParams.photo">
									<img style="width: 100%; cursor: pointer" id="display_default_photo" :src="config.main_path.replace('/index.php', '')+'/uploads/'+dataParams.photo" v-else>
								</label>
							</CCol>  
						</CRow>
			
						
					</CCard>
				</CCardBody>
			</form>
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import Datetime from 'vuejs-datetimepicker'; 
import moment from 'moment';



export default {
	mounted(){   
		
		this.getCustomer();
		this.getDepotList()
		this.getOriginList()
		if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.id){
			this.getData();
		} 

		document.getElementById('default_photo').onchange = ()=>{
			var file = document.getElementById('default_photo').files[0];
			var reader = new FileReader();
			var url = reader.readAsDataURL(file);

			reader.onloadend = function (e) {
				document.getElementById('display_default_photo').src = reader.result;
			}.bind(this);
		}

	},
	data(){
		return{
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",  
			dataParams: {
				origin_id: "",
				current_location_id: "",
				type: "",
				status: "",
				description: "",
				specification: "",
				serial_no: "",
				acquisition_date: "",
				body_no: "",
				asset_no: "",
				late_transfer_date: "",
				rental_date: "",
				mts_no: "",
				po_no: "",
				remarks: "",
				photo: "",
				acquisition_cost: "",
				rental_rate: "",
				dimentsion: "",
				model: "",
				mfg_brand: ""
			},
			editMode: false,
			selectedRow: {},
			
			isLoading: false,
			uploadPercentage: 0,
			config,


			customerList: {
				data: []
			},
			
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			destinationList: {
				data: []
			},
			
			fields:  [
				
			],
			
			user_data : JSON.parse(window.localStorage.getItem('user_data'))

		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, Datetime},
	methods: {  
		getDepotList(){

          axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
            }
          })
          .then(response => {
            this.depotList = response.data;
			let depot_id = this.user_data?.depot_id;
			if(depot_id) {
				let depot = this.depotList.data.filter(item => item.id == depot_id);
				if(depot.length) {
					this.$set(this.dataParams,'depot',depot[0])
				
					this.dataParams.depot_id = depot_id;
				}
			}
          })

        },
		locationChanged(ev) {
			let depot = [], depot_id = '';
			let customer = this.customerList.data.filter(item => item.id == ev);
			if(customer.length) {
				depot_id = customer[0].depot_id;
			}
			depot = this.depotList.data.filter(item => item.id == depot_id);
			
			if(depot.length) {
				this.dataParams.depot = depot[0];
				this.dataParams.depot_id = depot[0].id;
			}
			else {
				this.dataParams.depot = null;
				this.dataParams.depot_id = null;
			}
		},
	    submit(){ 

	    	if(this.isLoading){return;}

	    	this.isLoading = true;
	    	var formData = new FormData;

	    	if(this.dataParams.origin_id != ""){
	    		formData.append('origin_id', this.dataParams.origin_id);
	    	}
	    	if(this.dataParams.current_location_id != ""){
	    		formData.append('current_location_id', this.dataParams.current_location_id);
	    	}
	    	if(this.dataParams.type != "" && this.dataParams.type != null){
		    	formData.append('type', this.dataParams.type);
		    }
		    if(this.dataParams.status != ""){
	    		formData.append('status', this.dataParams.status);
	    	}
	    	if(this.dataParams.description != ""){
	    		formData.append('description', this.dataParams.description);
	    	}
	    	if(this.dataParams.specification != ""){
	    		formData.append('specification', this.dataParams.specification);
	    	}
	    	if(this.dataParams.serial_no != ""){
	    		formData.append('serial_no', this.dataParams.serial_no);
	    	}
	    	if(this.dataParams.acquisition_date != "" && this.dataParams.acquisition_date != null){
	    		formData.append('acquisition_date', moment(this.dataParams.acquisition_date).startOf('day').unix());
	    	}
	    	if(this.dataParams.body_no != ""){
	    		formData.append('body_no', this.dataParams.body_no);
	    	}
	    	if(this.dataParams.asset_no != "" || this.dataParams.asset_no != 'null'){
	    		formData.append('asset_no', this.dataParams.asset_no);
	    	}
			else
			formData.append('asset_no', '');
	    	if(this.dataParams.late_transfer_date != "" && this.dataParams.late_transfer_date != null){
	    		formData.append('late_transfer_date', moment(this.dataParams.late_transfer_date).startOf('day').unix());
	    	}
	    	if(this.dataParams.mts_no != ""){
	    		formData.append('mts_no', this.dataParams.mts_no);
	    	}
	    	if(this.dataParams.po_no != ""){
	    		formData.append('po_no', this.dataParams.po_no);
	    	}
	    	if(this.dataParams.remarks != ""){
	    		formData.append('remarks', this.dataParams.remarks);
	    	}
	    	if(document.getElementById('default_photo').files[0]){
	    		formData.append('photo', document.getElementById('default_photo').files[0]);
	    	}
	    	if(this.dataParams.acquisition_cost != ""){
	    		formData.append('acquisition_cost', this.dataParams.acquisition_cost);
	    	}
	    	if(this.dataParams.rental_rate != ""){
	    		formData.append('rental_rate', this.dataParams.rental_rate);
	    	} 
	    	if(this.dataParams.dimentsion != ""){
	    		formData.append('dimentsion', this.dataParams.dimentsion);
	    	}
	    	if(this.dataParams.model != ""){
	    		formData.append('model', this.dataParams.model);
	    	}
	    	if(this.dataParams.mfg_brand != ""){
	    		formData.append('mfg_brand', this.dataParams.mfg_brand);
	    	}

			if(this.dataParams.depot_id != ""){
	    		formData.append('depot_id', this.dataParams.depot_id);
	    	}  


	    	if(this.editMode){
	    		formData.append('method', "_PUT");
	    	} 
	    	axios.post(config.api_path+"/furnishing"+(this.editMode ? "/"+this.$route.params.id : ""), formData,  {
				onUploadProgress: (prog)=>{
					var uploadPercentage = parseInt( Math.round( ( prog.loaded / prog.total ) * 100 ));
					this.uploadPercentage = uploadPercentage;
				}, 
			})
	    	.then(response => {
	    		if(response.data.status == 'failed'){
	    			Swal.fire({
						title: 'Error!',
						text: response.data.message,
						icon: 'error', 
					})
					return;
	    		}
	    		var text = 'Furnishing successfully added!';
	    		if(this.editMode){
	    			text = 'Furnishing successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					origin_id: "",
					current_location_id: "",
					type: "",
					status: "",
					description: "",
					specification: "",
					serial_no: "",
					acquisition_date: "",
					body_no: "",
					asset_no: "",
					late_transfer_date: "",
					rental_date: "",
					mts_no: "",
					po_no: "",
					remarks: "",
					photo: "",
					acquisition_cost: "",
					rental_rate: "",
					dimentsion: "",
					model: "",
					mfg_brand: ""
				} 
	    		this.isLoading = false;
	    		this.$router.push('/main/view_furnishing/'+response.data.data.id)
	    	})
	    	.catch(e=>{
	    		this.isLoading = false;
	    	})
	    },
		
		 backToTable(){
	    	this.$router.push('/main/furnishing')
	    },


	    getCustomer(){

	    	axios.get(config.api_path+'/reference/customer-list')
	    	.then(response => {
	    		this.customerList = response.data;

	    	})

	    },
		getOriginList(){
	    	axios.get(config.api_path+'/reference/customer-list', {
				params: {
					show_all : true
				}
			})
	    	.then(response => {
	    		this.originList = response.data;
	    		
	    	})

	    },

	    getData(){
			this.$showLoading(true)

			axios.get(config.api_path+"/furnishing/"+this.$route.params.id)
			.then(response=>{  
				this.$showLoading(false)
				this.dataParams = response.data.data;    
				if(this.dataParams.acquisition_date){
					this.dataParams.acquisition_date = new Date(this.dataParams.acquisition_date * 1000);
				}
				if(this.dataParams.late_transfer_date){
					this.dataParams.late_transfer_date = new Date(this.dataParams.late_transfer_date * 1000);
				} 

				for (var key in this.dataParams) {
				    if (this.dataParams.hasOwnProperty(key)) {
				    	console.log(key)
				        if(this.dataParams[key] == "null" || this.dataParams[key] == null){
				        	this.dataParams[key] = ""
				        }
				    }
				}

			})

		}, 

	    clearSearch(){
	    	this.dataParams = { 
				origin_id: "",
				commodity_id: "", 
				delivery_date_from: "",
				delivery_date_to: "", 
			}
	    }
		

 	}, 
	 
}
</script>
